import { Component, OnInit } from '@angular/core';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationCheckService } from 'src/app/services/auth/authentication-check.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {

  loginForm;
  loginDetails
  errorMessage: Boolean = true
  submitted : boolean = false;

  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthenticationCheckService,
    private toast: ToastClientService
  ) {
    this.loginForm = formBuilder.group({
      otp: ['', Validators.compose([Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(6)])],
    });
  }

  ngOnInit() {
    // this.testApi();
   // this.loginDetails = JSON.parse(localStorage.getItem('login-details'))

  }
  get f(){
    return this.loginForm.controls;
  }

  login(value) {
    console.log(value)
    if(this.loginForm.invalid){
      this.errorMessage = false
      return
    }

    localStorage.setItem("program",'1')

    const formData = new FormData()

    let formsData = {
      'otp' : value.otp,
      'mobile_no' : JSON.parse(localStorage.getItem('user_mobile_no')),
      'voucher_code' : JSON.parse(localStorage.getItem('voucher_code')),
    }
    this.apiService.post(this.apiConfig.otp , formsData).subscribe((res: any)=>{
      console.log(res)
      if(res.body.status ==200){
        localStorage.setItem("user_detail", JSON.stringify(res.body.data))
        localStorage.setItem("login-details", JSON.stringify(res.body.data))
        localStorage.setItem("campaign_id", JSON.stringify(res.body.data.campaign_id))
        let token = `${res.body.data.auth_token}`;
        this.auth.saveAuth(token);
        this.router.navigate(["catalogue"]);
      }
    },
    (err) => {

    })
  }

  isValid(field): boolean {
    let isValid: boolean = false;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this.loginForm.controls[field].touched == false) {
      isValid = true;
    }
    // If the field is touched and valid value, then it is considered as valid.
    else if (this.loginForm.controls[field].touched == true && this.loginForm.controls[field].valid == true) {
      isValid = true;
    }
    return isValid;
  }
}
