import { Component, OnInit } from '@angular/core';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationCheckService } from 'src/app/services/auth/authentication-check.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  loginForm;
  loginDetails
  errorMessage: Boolean = true
  submitted : boolean = false;

  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthenticationCheckService,
    private toast: ToastClientService
  ) {
    this.checkLogin();
    this.loginForm = formBuilder.group({
      voucher_code: ['', Validators.compose([Validators.required])],
      mobile_no: ['', Validators.compose([Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)])],
    });
  }

  ngOnInit() {
    // this.testApi();
    this.loginDetails = JSON.parse(localStorage.getItem('login-details'))

  }

  checkLogin() {
    let previousURL = localStorage.getItem("previousURL")
    let login = localStorage.getItem("login")
    if (login == 'true') {
      this.router.navigateByUrl(previousURL)
    }
  }


  login(value) {
    this.submitted = true;
    //console.log(value)
    if(this.loginForm.invalid){
      this.errorMessage = false
      return
    }
    localStorage.setItem("program",'1')
    this.apiService.post(this.apiConfig.login , value).subscribe((res: any) => {
      if (res.status == 200) {
        this.submitted = false;
        //console.log(res)
        localStorage.setItem("user_mobile_no", JSON.stringify(value.mobile_no))
        localStorage.setItem("voucher_code", JSON.stringify(value.voucher_code))
        this.router.navigate(["otp"]);
      }
      console.log(res, 'fail')
    },
    (err) => {
      //this.toast.Success(err.body.data.message);
    })
  }


  isValid(field): boolean {
    let isValid: boolean = false;
    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this.loginForm.controls[field].touched == false) {
      isValid = true;
    }
    // If the field is touched and valid value, then it is considered as valid.
    else if (this.loginForm.controls[field].touched == true && this.loginForm.controls[field].valid == true) {
      isValid = true;
    }
    return isValid;
  }
  get f(){
    return this.loginForm.controls
  }
}



// testApi(){
//   this.apiService.get(this.apiConfig.baseUrl2)
//   .subscribe((res:any)=>{
//     console.log('success', res)
//   },err=>{
//     console.log('failed', err);
//   })
// }
