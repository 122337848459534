/* tslint:disable */
import { Injectable } from "@angular/core";

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {
  // Staging

  baseUrl2 = 'https://api.akamai.bigcityvoucher.co.in/v1/';
  // Prod
//  baseUrl2 = 'https://appapi.redemptionportalrbppl.in/v1/';

  //baseUrl2 = 'https://api.stashfin.bigcityexperience.com/v1/';

  login = this.baseUrl2 + "stashfin/customer-login"
  otp = this.baseUrl2 + "stashfin/validate-otp"
  //getUserPoints = this.baseUrl2 + 'users/get-user-profile'
  getUserPoints = this.baseUrl2 + 'customer/get-user-profile'

  products = this.baseUrl2 + 'product/list-product'
  addToCart = this.baseUrl2 + 'rewards/savecart'
  getCartDetails = this.baseUrl2 + 'rewards/list-cart'
  categories = this.baseUrl2 + 'product/list-product-cats?flag'
  getOTP = this.baseUrl2 + 'users/generate-otp'
  checkout = this.baseUrl2 + 'rewards/orders'
  deleteProductInCart = this.baseUrl2 + 'rewards/deletecartbyprod'
  redemptionSummary = this.baseUrl2 +'rewards/redemption-summary'
  dashboardDetails = this.baseUrl2 +'rewards/userdashboard'
  pointsSummary = this.baseUrl2 + 'rewards/point-summary'
  clearCart = this.baseUrl2 + 'rewards/clear-cart'
  editEmail = this.baseUrl2 + 'customer/editemail'
  addDeleteCartItem = this.baseUrl2+ 'rewards/adddeletecartitems'
}
export interface ApiConfigurationInterface {

}

