import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './common/signin/signin.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { OtpComponent } from './common/otp/otp.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: 'signin',
    // canActivate: [AuthGuardService],
    component: SigninComponent,
  },
  {
    path: 'otp',
    // canActivate: [AuthGuardService],
    component: OtpComponent,
  },
  {
    path: 'catalogue',
    // canActivate: [AuthGuardService],
    loadChildren: () => import('./catalogue-module/catalogue.module').then(m => m.CatalogueModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // useHash: true
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
